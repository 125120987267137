import moment from "moment";
const BASE_URL = "https://portal.taimba.co.ke/";
// const BASE_URL = "https://testodoo.taimba.co.ke/";
const UserID = +sessionStorage.getItem("us");
const user_route = +sessionStorage.getItem("rt");
// const DB = "taimba_staging_14";
const DB = "taimba_14";

export function UserLogin(email, password) {
  return fetch(BASE_URL + `user_login`, {
    headers: new Headers({
      // Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      jsonrpc: 2.0,
      params: {
        db: DB,
        login: email,
        password: password,
      },
    }),
  });
}

export function resetPassWord(email, password) {
  return fetch(BASE_URL + `ForgotPassword`, {
    headers: new Headers({
      // Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      jsonrpc: 2.0,
      params: {
        // db: "taimba_staging_14",
        phone_number: email,
        new_passwd: password,
      },
    }),
  });
}

// Params: phone number, new passowrd

export function getProduct() {
  return fetch(BASE_URL + `GetProducts`, {
    headers: new Headers({
      // Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      jsonrpc: 2.0,
    }),
  });
}

export function getOrders() {
  return fetch(BASE_URL + `GetOrdersByPartner`, {
    headers: new Headers({
      // Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({ jsonrpc: 2.0, params: { partner_id: UserID } }),
  });
}

export function getRoutes() {
  return fetch(BASE_URL + `GetEcommerceRoutes`, {
    headers: new Headers({
      // Funguo: Funguo,
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      jsonrpc: 2.0,
    }),
  });
}

export function newCreatOrderProduct(values) {
  return fetch(BASE_URL + `CreateSaleOrders`, {
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      jsonrpc: 2.0,
      params: {
        partner_id: UserID,
        date_order: moment().format("YYYY-MM-DD HH:mm:ss"),
        route_id: Number(user_route),
        order_amount: values.grandTotal,
        order_details: values.product,
        user_id: UserID,
      },
    }),
  });
}

export function RegisterUser(data) {
  return fetch(BASE_URL + `RegisterCustomer`, {
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    }),
    method: "post",
    body: JSON.stringify({
      jsonrpc: 2.0,
      params: {
        name: data.firstName + data.lastName,
        phone_number: data.phoneNumber,
        email: data.email,
        firstname: data.firstName,
        lastname: data.lastName,
        password: data.password,
        route_id: Number(data.routeID),
        customer_type_id: 1,
        partner_latitude: 0,
        partner_longitude: 0,
      },
    }),
  });
}
